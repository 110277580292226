@import url("https://fonts.googleapis.com/css2?family=Assistant&family=Roboto:ital,wght@1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;800&family=Roboto:ital,wght@1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;800&family=Roboto:ital,wght@1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700;800&family=Roboto:ital,wght@1,100&display=swap");
* {
  font-family: Assistant;
  color: #152745;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root{
 
    width: 100%;
    display: flex;
    justify-content: center;
 
}

.App{
    max-width: 441px !important;
    padding-inline: 33px;
    padding-block: 33px;
}
body{
    overflow-x: hidden;
}

.homepage{
    max-width: 441px !important;
    .navbar{
       

        p{
            width: 155px;
height: 30px;
top: 22px;
left: 74px;
gap: 0px;
font-family: Assistant;
font-size: 30px;
font-weight: 700;
line-height: 30px;
text-align: left;
color: #152745;
box-shadow: 0px 2px 4px 0px #1527450D;
width: 100%;
height: 48px;
        }
        img{
            width: 80px;
            margin-top: 27px;
        }
    }
    .searchbar{
        margin-top: 25px;
        input{
            background-image: url('../src/common/Images/search.svg');
            background-repeat: no-repeat;
            background-position: 3%;
            padding-inline: 20%;
            outline: none;
            height: 57px;
            width: 340px;
            border: 1px solid #15274533;
            border-radius: 10px;
            font-size: 1.1rem;
           
        }
    }
    .banklisting{
        margin-top: 47px;
        max-width: 340px !important;
        .banks{
            margin-top: 8px;
            .mybank{
                display: flex;
                gap: 17px;
                cursor: pointer;
                img{
                    height: 38px;
                }
                span{
                    font-family: Assistant;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 26.54px;
                    text-align: left;
                    color: #152745;     

                }
              
            }
            
            hr{
                    margin-top: 14px;   
                    border: 1px solid #15274512;
                    // width: 362px;
                }
            
        }
    }
}

.finalresultcontent{
    margin-top: 84px;
    .content{
        text-align: center;
        h1{
            margin-top: 19px;
        }
        p{
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 39px;
        }
        .finalpending{
            margin-top: 3px;
        }
    }
    .wait{
        // margin-top: 20px;
        font-family: Assistant;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    
    }
    .myybtn{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 94px;
     button{
        width: 240px;
        height: 60px;
    
    padding: 20px, 25px, 20px, 25px;
    border-radius: 10px;
    border: 1px solid #FC6603;
    font-family: Assistant;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    outline: none;
    background-color:#FFFFFF;
    color:#FC6603 ;
    
     }
    }
  
}


@media screen and (max-width: 370px) {
    .homepage .searchbar input {
        width: 290px !important;
    }
    .homepage .banklisting{
        max-width: 290px !important;
    }
  }

// @media screen and (max-width: 324px) {
//     .homepage .searchbar input {
//         min-width: 271px !important ;
//     }
//   }



  