
.loader-text {
    padding-top: 50px;
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    color: #ff5700;
  }
  
  .loader_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    z-index: 999999;
    transform: translate(-50%, -50%);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .lds-roller {
    display: inline-block;
    width: 64px;
    position: absolute;
    height: 64px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #ff5700;
    margin: -3px 0 0 -3px;
  }
  
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  ._3uApM {
    display: none !important;
  }
  ._2JvrO {
    background: #ff5700 !important;
  }
  ._2kL0S {
    background: #ff5700 !important;
  }
  /* ._2Jtxm {
    background-color: #e7eaf3;
  } */
  
  ._53Ji7 {
    padding: 0rem !important;
  }
  .powredby {
    text-align: center;
  }
  
  .custom-inpt {
    padding: 10px !important;
  }
  